@import "../../../assets/styles/global.scss";

.detail_job {
  @extend .main-mt;

  .bread_crumb_container {
    @extend .my-container;
    @apply grid-cols-12 hidden lg:grid;
    .bread_crumb {
      @apply col-span-full xl:col-start-2 xl:col-end-12;
    }
  }

  .title_and_paragraph {
    @extend .my-container;
    @apply grid-cols-12 grid;

    h1 {
      @extend .my-h1;
      @apply col-span-full xl:col-start-2 xl:col-end-12 mt-[4px];
    }

    .paragraph_container {
      @apply col-span-full xl:col-start-2 xl:col-end-7;
      @apply mt-[16px] lg:mt-[32px];

      p {
        @extend .paragraph;
      }
    }
  }

  .details_container {
    @extend .my-container;
    @apply grid grid-cols-12 mt-[40px] md:mt-[80px] 2xl:mt-[120px];

    .item {
      @apply col-span-full xl:col-start-2 xl:col-span-10 bg-white py-[32px] md:py-[48px] 2xl:py-[64px] first:mt-0 mt-[40px] md:mt-[80px] 2xl:mt-[120px];
      @apply grid-cols-6 md:grid-cols-12 xl:grid-cols-10 grid md:items-baseline;

      h2 {
        @apply col-start-2 col-span-4 md:col-span-3 md:pl-[48px] 2xl:pl-[64px];
        @extend .my-h2;
      }

      .text_container {
        @apply col-start-2 col-span-4 md:col-span-7 md:col-start-5 xl:col-start-5 xl:col-span-5 md:mt-0 list-disc pl-[16px] md:pl-[32px] mt-[8px];
        li {
          @extend .paragraph;
        }
      }
    }
  }
}
