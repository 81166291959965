@import "../../../assets/styles/global.scss";

.realisations {
  @extend .my-container;
  @extend .main-mt;
  @apply grid grid-cols-12;

  .full {
    @apply col-span-full xl:col-start-2 xl:col-end-12;
  }

  .bread_crumb {
    @extend .full;
    @apply hidden lg:block;
  }

  h1 {
    @extend .my-h1;
    @extend .full;
    @apply mt-[4px];
  }

  .first_p {
    @extend .paragraph;
    @apply col-span-full xl:col-start-2 xl:col-end-11;
    @apply mt-[16px] lg:mt-[32px];
    span {
      @apply font-medium;
    }
  }

  .images_container {
    @extend .full;
    @apply grid grid-cols-10 gap-y-0 sm:gap-y-[16px] 2xl:gap-y-[20px] mt-[40px] lg:mt-[80px] 2xl:mt-[120px];
    .label_image {
      @extend .my-h2;
      @apply border-b sm:border-b-0 border-t-0 sm:border-t sm:pt-[8px] border-flush-orange-500;
    }

    .image_container {
      @apply overflow-hidden cursor-pointer mt-[8px] sm:mt-0;
      img {
        transform: scale(1);
        transition: transform 0.25s;
      }
      &:hover {
        img {
          transform: scale(1.05);
          transition: transform 0.25s;
        }
      }
    }

    .row {
      @apply col-span-full grid grid-cols-12 sm:grid-cols-10 gap-x-[16px] lg:gap-x-[20px] relative;
    }

    img {
      @apply w-full h-[251px] sm:h-auto object-center object-cover;
    }

    .spancing_block {
      @apply mt-[40px] sm:mt-0;
    }

    .first_row {
      @extend .row;
      @apply row-start-1 row-end-2;

      .label_image {
        @apply sm:pl-[16px];
        @apply text-left sm:text-right;
      }

      .first_image {
        @apply col-span-full sm:col-start-1 sm:col-end-9 grid grid-cols-6 sm:grid-cols-8 relative gap-x-[20px];
        .image_container {
          @apply col-span-full sm:col-start-1 sm:col-end-6 2xl:col-end-7 w-full;
        }
        .label_image {
          @apply col-span-full sm:col-start-6 2xl:col-start-7 sm:col-end-9 row-start-1 row-end-2 sm:ml-[-20px];
        }
      }

      .second_image {
        @extend .spancing_block;
        @apply relative sm:absolute bottom-0 z-[1] col-span-full sm:col-start-6 2xl:col-start-7 sm:col-end-11 grid grid-cols-6 sm:grid-cols-5 2xl:grid-cols-4 w-full;
        .image_container {
          @apply w-full col-span-full sm:col-start-1 sm:col-end-4 2xl:col-end-3;
          .xl_2 {
            @apply hidden 2xl:block;
          }
          .xl {
            @apply block 2xl:hidden;
          }
        }
        .label_image {
          @apply col-span-full sm:col-start-4 2xl:col-start-3 sm:col-end-6 2xl:col-end-5 sm:row-start-1 sm:row-end-2;
        }
      }
    }

    .second_row {
      @extend .row;
      @apply row-start-2 row-end-4;
      @extend .spancing_block;

      .first_image {
        @apply relative sm:absolute z-[1] bottom-0 col-span-full sm:col-start-2 sm:col-end-6 w-full grid grid-cols-6 sm:grid-cols-4;
        @apply row-start-2 row-end-3;
        @extend .spancing_block;
        .image_container {
          @apply col-span-full sm:col-start-3 sm:col-end-5;
        }
        .label_image {
          @apply col-span-full sm:col-start-1 sm:col-end-3 text-left sm:mr-[-20px];
        }
      }

      .second_image {
        @apply row-start-1 row-end-2;
        @apply col-span-full sm:col-start-4 sm:col-end-11 relative grid grid-cols-6 sm:grid-cols-7 gap-x-[20px];
        .image_container {
          @apply col-span-full sm:col-start-3 sm:col-end-8;
        }
        .label_image {
          @apply col-span-full sm:col-start-1 sm:col-end-3 text-left sm:mr-[-20px];
        }
      }
    }

    .third_row {
      @extend .row;
      @apply row-start-4 row-end-5;
      @extend .spancing_block;
      .first_image {
        @apply col-span-full sm:col-start-1 sm:col-end-6 grid grid-cols-6 sm:grid-cols-5;
        .label_image {
          @apply col-span-full sm:col-start-1 sm:col-end-3;
        }
        .image_container {
          @apply col-span-full sm:col-start-3 sm:col-end-8;
        }
      }

      .second_image {
        @extend .spancing_block;
        @apply col-span-full sm:col-start-6 sm:col-end-11 grid grid-cols-6 sm:grid-cols-5;
        .label_image {
          @apply col-span-full sm:col-start-4 sm:col-end-6 sm:row-start-1 sm:row-end-2;
          @apply text-left sm:text-right;
        }
        .image_container {
          @apply col-span-full sm:col-start-1 sm:col-end-4 sm:row-start-1 sm:row-end-2;
        }
      }
    }
  }
}
