.my-container {
  @apply px-[30px] lg:px-[60px] w-full;
}

.spacing-block {
  @apply pt-[80px] lg:pt-[216px] 2xl:pt-[272px];
}

.my-h1 {
  @apply font-apollo text-lg sm:text-2xl lg:text-3xl 2xl:text-4xl tracking-[0.08em];
}

.my-h2 {
  @apply font-apollo text-sm-apollo sm:text-base-apollo lg:text-lg 2xl:text-2xl tracking-[0.06em];
}

.paragraph {
  @apply font-poppins font-light text-xs lg:text-sm 2xl:text-base;
}

.main-mt {
  @apply mt-[40px] lg:mt-[106px] 2xl:mt-[96px];
}

.my-h3 {
  @apply font-apollo text-xs-apollo lg:text-sm-apollo 2xl:text-lg tracking-[0.06em];
}

.swiper-scrollbar-drag {
  height: 2px !important;
  background-color: #ff9a0a !important;
  border-radius: 0 !important;
}
