@import "../../../assets/styles/global.scss";

.second_block {
  @extend .my-container;
  @apply bg-background-white grid grid-cols-12 mt-[40px] md:mt-[80px] lg:mt-[108px] 2xl:mt-[138px] relative py-[40px] md:py-[80px] lg:py-[108px] 2xl:py-[138px];

  h2 {
    @extend .my-h2;
  }

  p {
    @apply mt-[16px] md:mt-[24px] 2xl:mt-[32px];
    @extend .paragraph;
  }

  .btn_container {
    @apply mt-[16px] md:mt-[24px] 2xl:mt-[32px];
  }

  .first_row {
    @apply col-span-full md:col-start-3 xl:col-start-5 md:col-end-13 lg:col-end-12 relative z-[1];
    .contents {
      @apply grid grid-cols-12 md:grid-cols-10 lg:grid-cols-7 gap-x-[20px];
      h2 {
        @apply block md:hidden col-span-full;
      }
      .img {
        @apply mt-[8px] md:mt-[0px] col-span-full md:col-start-1 md:col-end-6 lg:col-end-4 2xl:col-end-5 w-full h-auto;
      }
      .texts {
        @apply col-span-full md:col-start-6 lg:col-start-4 2xl:col-start-5 md:col-end-11 lg:col-end-8 place-self-center;
        .title {
          @apply hidden md:block;
        }
      }
    }
  }

  .second_row {
    @apply col-span-full xl:col-start-2 xl:col-end-12 mt-[80px] 2xl:mt-[120px] relative z-[1];
    .contents {
      @apply grid grid-cols-12 xl:grid-cols-10 gap-x-[20px];
      h2 {
        @apply block md:hidden col-span-full row-start-1 row-end-2;
      }
      .texts {
        @apply col-span-full md:col-start-1  md:col-end-7 xl:col-end-6 2xl:col-end-5 place-self-center 2xl:place-self-start 2xl:mt-[123px];
        @apply row-start-3 row-end-4 md:row-start-1 md:row-end-2;
        .title {
          @apply hidden md:block;
        }
      }
      .img {
        @apply mt-[8px] md:mt-0 col-span-full md:col-start-7 xl:col-start-6 2xl:col-start-5 md:col-end-13 xl:col-end-11;
        @apply row-start-2 row-end-3 md:row-start-1 md:row-end-2;
      }
    }
  }

  .bg_white_container {
    @apply hidden md:block absolute z-0 col-start-2 xl:col-start-3 col-end-12 lg:col-end-11 md:top-[160px] lg:top-[208px] xl:top-[220.7px] 2xl:top-[395px] w-full;
    img {
      @apply w-full h-[580px] lg:h-[608px] 2xl:h-auto;
    }
  }
}

.nos_valeurs {
  @extend .my-container;
  @apply grid grid-cols-12 items-baseline py-[40px] md:py-[80px] lg:py-[108px] 2xl:py-[136px];

  .h2_title {
    @apply col-span-full md:col-start-1 md:col-end-4 xl:col-start-2 xl:col-end-5 row-start-1 row-end-2;
  }

  .title_whith_img {
    @extend .my-h3;
    @apply inline-block relative pl-[24px] md:pl-0;
    .title_span {
      @apply relative z-[1];
    }
    img {
      @apply h-[36px] w-[36px] md:h-[40px] md:w-[40px] lg:h-[52px] lg:w-[52px] 2xl:w-[78px] 2xl:h-[78px];
      @apply absolute z-0 top-[50%] left-[-2px] md:left-[-25px] lg:left-[-30px] 2xl:left-[-50px];
      transform: translateY(-50%);
    }
  }

  .item {
    .paragraph_container {
      @apply mt-[16px] md:mt-[24px] xl:mt-[32px];
      p {
        @extend .paragraph;
        @apply pl-[24px] md:pl-0;
      }
    }
    @apply mt-[50px] md:mt-0;
  }

  .item:first-of-type {
    @apply mt-[26px] md:mt-0;
  }

  .col_1 {
    @apply col-span-full md:col-start-4 xl:col-start-5 md:col-end-8;
  }

  .col_2 {
    @apply col-span-full md:col-start-9 md:col-end-13 xl:col-end-12;
  }

  .item:nth-of-type(1) {
    @apply row-start-2 row-end-3 md:row-start-1 md:row-end-2;
    @extend .col_1;
  }

  .item:nth-of-type(2) {
    @apply row-start-4 md:row-start-1 row-end-4 md:row-end-2;
    @extend .col_2;
  }

  .item:nth-of-type(3) {
    @apply row-start-5 row-end-6 md:row-start-2 md:row-end-3 md:mt-[32px] lg:mt-[64px] 2xl:mt-[120px];
    @extend .col_1;
  }

  .item:nth-of-type(4) {
    @apply row-start-6 row-end-7 md:row-start-2 md:row-end-3  md:mt-[32px] lg:mt-[64px] 2xl:mt-[120px];
    @extend .col_2;
  }
}

.teams {
  @extend .my-container;
  @apply grid grid-cols-12  bg-background md:bg-background-white lg:pb-[16px] pt-[40px] md:pt-[80px] lg:pt-[108px] 2xl:pt-[138px];

  .title {
    @apply col-span-full xl:col-start-2 xl:col-end-12 row-start-1 row-end-2;
  }

  .text {
    @extend .paragraph;
    @apply col-span-full xl:col-start-2 xl:col-end-8 row-start-2 row-end-3 mt-[16px] md:mt-[32px];
  }

  .teams_container {
    @apply mt-[40px] md:mt-[80px] 2xl:mt-[120px];
    @apply row-start-3 row-end-4 col-span-full xl:col-start-2 xl:col-end-12 md:bg-background grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-12 2xl:grid-cols-8;

    .team_item {
      @apply flex flex-col bg-background-white;

      .img_container {
        @apply bg-background-white md:bg-background w-full flex justify-center items-center md:pt-[47px] 2xl:pt-[94px];

        img {
          position: relative !important;
          @apply w-auto h-[232px] md:h-[187px] 2xl:h-[208px] object-cover object-top;
        }
      }

      .infos {
        @apply bg-background md:bg-background-white flex flex-col items-center pb-[40px] md:pb-[80px] 2xl:pb-[60px];
        h3 {
          @extend .my-h3;
          @apply text-center mt-[8px] md:mt-[12px] 2xl:mt-[16px];
        }
        .text_container {
          @extend .paragraph;
          @apply w-full max-w-full md:max-w-[175px] 2xl:max-w-[246px] mt-[8px] md:mt-[12px] 2xl:mt-[16px] text-center;
        }
      }
    }

    .team_item:nth-of-type(4n + 1) {
      @apply xl:col-span-3 2xl:col-span-2;
    }

    .team_item:nth-of-type(4n + 2) {
      @apply xl:col-span-2;
    }

    .team_item:nth-of-type(4n + 3) {
      @apply xl:col-span-2;
    }

    .team_item:nth-of-type(4n + 4) {
      @apply xl:col-span-3 2xl:col-span-2;
    }
  }
}