.swiper-pagination-bullet {
  height: 10px !important;
  width: 10px !important;
  margin: 0px 8px !important;
  background-color: black;
  opacity: 1 !important;
}
.swiper-pagination-bullet-active {
  background: #ff8200 !important;
}

.swiper-pagination {
  bottom: -34px !important;
  width: calc(100% + 30px) !important;
  margin-left: -30px;
}

.swiper {
  overflow: visible !important;
}
