@import "../../../assets/styles/global.scss";

.detail_realisation {
  @extend .my-container;
  @extend .main-mt;
  @apply grid grid-cols-12 relative;

  .left {
    @apply col-start-1 xl:col-start-2 col-end-8 xl:col-end-6 relative z-[0] pr-[40px] xl:pr-0;
    .bread_crumb {
      @apply hidden lg:flex;
    }
    h1 {
      @extend .my-h1;
      @apply mt-[4px] whitespace-nowrap;
    }
  }

  .right {
    @apply col-start-8 xl:col-start-7 col-end-13 xl:col-end-12 relative z-[0];
    img {
      @apply w-full lg:h-[637px] 2xl:h-[901px] object-cover object-center;
    }
  }

  .description_container {
    @apply mt-[16px] xl:mt-[32px];
    p {
      @extend .paragraph;
      @apply first:mt-0 mt-[24px] 2xl:mt-[30px];
    }
  }

  .others {
    @apply absolute z-[1] lg:top-[60%] xl:top-[64%] col-start-4 col-end-9 xl:col-end-8 flex bg-background w-full gap-x-[16px] ml-[-10px] p-[10px];
    flex: 1;
    .other_image_container {
      @apply flex-1 inline-block;
      aspect-ratio: 1/1;
      img {
        @apply w-full h-full object-cover object-center;
      }
    }
  }

  @media (max-width: 1200px) {
    .left {
      @apply col-span-full pr-0;
    }
    .right {
      @apply col-span-full mt-[40px];
      img {
        @apply h-[650px];
      }
    }
    .others {
      @apply relative col-span-full top-0 mt-[-10%] bg-transparent  px-[40px] ml-0;
      .other_image_container {
        @apply bg-background p-[4px];
      }
    }
  }

  @media (max-width: 768px) {
    .right {
      img {
        @apply h-[400px];
      }
    }
    .others {
      @apply p-0 justify-evenly gap-x-0 mt-[-32px];
      .other_image_container {
        @apply max-w-[72px] max-h-[72px];
      }
    }
  }

  @media (max-width: 500px) {
    .right {
      img {
        @apply h-[390px];
      }
    }
  }
}

.navigations {
  @extend .my-container;
  @apply grid grid-cols-12;
  .navigation_container {
    @apply col-span-full xl:col-start-2 xl:col-end-12 mt-[40px] lg:mt-[80px] 2xl:mt-[120px] grid grid-cols-6  sm:grid-cols-12 xl:grid-cols-10;
    label {
      @apply block w-full font-apollo text-sm-apollo lg:text-lg 2xl:text-2xl tracking-[0.06em] cursor-pointer lg:mb-[-4px] xl:mb-[-8px];
    }
    .prev {
      @apply col-start-1 col-end-3;
      label {
        @apply text-right;
      }
    }
    .next {
      @apply col-start-5 col-end-7 sm:col-start-11 sm:col-end-13 xl:col-start-9 xl:col-end-11;
      label {
        @apply text-left;
      }
    }
  }
}
