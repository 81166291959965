@import "../../../assets/styles/global.scss";

.contents {
  @extend .my-container;
  @apply grid grid-cols-12 mt-[80px] lg:mt-[216px] 2xl:mt-[272px];
  .h2 {
    @apply col-span-full xl:col-start-2 xl:col-end-12;
  }
  p {
    @extend .paragraph;
    @apply col-span-full lg:col-start-1 xl:col-start-2 lg:col-end-7 mt-[16px] md:mt-[24px] lg:mt-[32px];
  }
}

.card_container {
  @apply w-full mt-[40px] sm:mt-[64px] lg:mt-[80px] 2xl:mt-[120px];

  .spacing_content_card {
    @apply mt-[8px] sm:mt-[16px] 2xl:mt-[32px];
  }

  h2 {
    @extend .spacing_content_card;
  }

  .paragraphs {
    @extend .spacing_content_card;
    p {
      @extend .paragraph;
    }
  }
  .btn_container {
    @extend .spacing_content_card;
    button {
      @apply px-[16px] py-[10px] lg:px-[24px] lg:py-[12px];
    }
  }
}
