@import "../../../assets/styles/global.scss";

.spacing_content_card {
  @apply mt-[8px] sm:mt-[16px] 2xl:mt-[32px];
}

.card {
  h2 {
    @extend .spacing_content_card;
  }

  .paragraphs {
    @extend .spacing_content_card;
    p {
      @extend .paragraph;
    }
  }
  .btn_container {
    @extend .spacing_content_card;
    button {
      @apply px-[16px] py-[10px] lg:px-[24px] lg:py-[12px];
    }
  }
}
