@import "../../../assets/styles/global.scss";

.container_fixed {
  @apply w-full h-screen absolute z-[-1] top-0;
  .container_relative {
    @apply relative w-full h-full;

    .image_container {
      @apply absolute  top-[50%] left-[50%] h-[270px] md:h-[44.72%] w-[144px] md:w-[17.7083%];
      transform: translate(-50%, -50%);
      img {
        @apply w-full h-full object-cover object-center opacity-50;
      }
    }

    .mainTitle {
      @apply top-[50%] left-[50%] absolute z-[1] w-full px-[16px];
      @apply font-apollo text-center tracking-[0.08em] text-[48px] leading-[56px] md:text-[80px] lg:text-[96px] lg:leading-[112.22px] 2xl:text-[128px]  2xl:leading-[149.63px];
      transform: translate(-50%, -50%);
    }
  }
}

.navbar_container {
  @apply w-full h-screen relative z-[1000];
  .navbar_container_absolute {
    @apply absolute top-0 w-full;
  }
}

.contents {
  @apply w-full bg-background;
  ._block {
    @extend .my-container;
    @apply grid grid-cols-12 pt-[40px] md:pt-[80px] lg:pt-[108px] 2xl:pt-[138px];

    .bread_crumb {
      @apply col-start-1 xl:col-start-2 col-end-13 hidden lg:flex;
    }

    .title_h2 {
      @extend .my-h1;
      @apply mt-[4px] col-start-1 xl:col-start-2 col-end-13 xl:col-end-12;
    }

    .text_savoir_faire {
      p {
        @extend .paragraph;
        span {
          @apply font-semibold;
        }
      }
      @apply col-start-1 xl:col-start-2 col-end-13 xl:col-end-12;
      @apply mt-[16px] lg:mt-[32px];
    }

    .left_desktop {
      @apply hidden md:block col-start-1 xl:col-start-2 col-end-10 lg:col-end-9 relative;
      @apply pt-[37px] 2xl:pt-[55px] pb-[12px] 2xl:pb-[108px] mt-[48px] 2xl:mt-[65px];

      .block_orange {
        @apply h-full w-[92px] 2xl:w-[132px] bg-brand-2 absolute top-0 left-[44px] z-0;
      }

      .desciptions {
        @apply w-full relative z-[1];

        .item {
          @apply grid grid-cols-9 lg:grid-cols-8 xl:grid-cols-7 first:mt-0 mt-[68px] 2xl:mt-[118px];

          .item_left {
            @apply col-start-1 col-end-5 lg:col-end-4;
            .img_container {
              @apply py-3 2xl:py-4 bg-background;
              img {
                @apply w-full h-[175px] xl:h-[235px] object-cover object-center;
              }
            }
            h3 {
              @extend .my-h3;
              @apply mt-[16px];
            }
          }

          .item_right {
            @apply col-start-6 lg:col-start-5 col-end-10 lg:col-end-9 xl:col-end-8 mt-[16px];
            p {
              @extend .paragraph;
            }
          }
        }
      }
    }

    .items_mobile {
      @apply col-span-full block md:hidden ml-[-30px] pl-[30px] mt-[24px] overflow-hidden pb-[40px];
      width: calc(100% + 60px);
    }

    .right {
      @apply col-span-full md:col-start-10 md:col-end-13 xl:col-end-12 flex md:flex-col md:items-end mt-[40px] md:mt-[80px] 2xl:mt-[120px] flex-wrap;
      @apply gap-[16px] md:gap-0;

      .h2 {
        @apply mr-[16px] w-full md:w-auto;
      }

      .service_btn {
        @apply md:mt-[24px] lg:mt-[32px];
      }
    }
  }
}

.slideItem_container {
  @apply w-full h-auto;
  .image_container_slide {
    @apply relative w-full h-[172px];
    img {
      @apply object-cover object-center;
    }
  }
  .descritpion_container {
    @apply pt-[16px] pb-[24px] px-[16px] bg-background-white;
    h3 {
      @extend .my-h3;
      @apply text-center;
    }
    .text_slide {
      @extend .paragraph;
      @apply text-center;
    }
  }
}

.plan {
  @extend .my-container;
  @apply grid grid-cols-12 mt-[80px] md:mt-[120px] lg:mt-[216px] 2xl:mt-[246px] 2xl:pb-[246px];

  h2 {
    @apply col-span-full xl:col-start-2 xl:col-end-12;
  }

  .plan_mobile {
    @apply mt-[32px] col-span-full  relative;

    .navigation {
      @apply inline-block float-right sticky top-[24px] mr-[-15px] z-[1];
      .navigation_container {
        @apply flex flex-col gap-y-2;

        img {
          @apply h-[50px] w-[8px] object-cover;
        }

        span {
          &:first-child {
            img {
              @apply object-top;
            }
          }
          &:last-child {
            img {
              @apply object-bottom;
            }
          }
        }
      }
    }

    .items {
      @apply relative z-[0] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-y-[32px] md:gap-4 lg:gap-6;

      .service_item {
        @apply bg-white py-[16px] px-[8px] flex flex-col items-center gap-y-[8px];

        h3 {
          @extend .my-h3;
        }

        .text {
          p {
            @extend .paragraph;
            @apply text-center;

            span {
              @apply font-bold;
            }
          }
        }
      }
    }
  }

  .spacing-top {
    @apply mt-[40px] lg:mt-[80px] 2xl:mt-[161px];
  }

  .text_content {
    @extend .spacing-top;
    @apply col-span-full md:col-start-1 md:col-end-6 xl:col-start-2 xl:col-end-5;

    h3 {
      @extend .my-h3;
      @apply uppercase;
    }

    .details_text_container {
      @apply mt-[16px] xl:mt-[24px];
      p {
        @extend .paragraph;
        span {
          @apply font-semibold;
        }
      }
    }
  }

  .plan_container {
    @extend .spacing-top;
    @apply col-span-full md:col-start-7 md:col-end-13 xl:col-start-6 xl:col-end-12;
  }
}
